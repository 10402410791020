if (module.hot) {
  module.hot.accept();
}

import 'slick-carousel';
import Inputmask from 'inputmask';
import axios from 'axios';
import Scrollbar from 'smooth-scrollbar';

const $axios = axios;

const WOW = require('wow.js');
window.wow = new WOW();
window.wow.init();

// Scrollbar.init(document.querySelector('.scrollbar-track'));






// *****************************************************************
//      function isElementExist
// *****************************************************************

function isElementExist(elem) {
	return $(elem) && $(elem).length > 0;
}

// import 'jquery-mousewheel';
// import 'malihu-custom-scrollbar-plugin';
// import 'jquery-mask-plugin';
// import 'webpack-jquery-ui/css';
// import 'webpack-jquery-ui/selectmenu';


const forms = document.querySelectorAll('.form');

forms.forEach(form => {
  form.addEventListener('submit', submitForm)
});

function submitForm(e) {
  e.preventDefault();
  const form = e.target;
  if (validateForm(form)) {
    const url = './contact.php';
    const formData = new FormData(form);
    
    $axios.post(url, formData)
      .then(response => {
        if (response.status == 200) {
          form.parentElement.querySelector('.form-success').classList.remove('d-none');
          form.classList.add('d-none');
        }
      })
      .catch(error => {
        alert('something went wrong');
      })
  } 
}

const inpMask = new Inputmask('+1 - 999 - 999 - 9999');
const phones = document.querySelectorAll('[name="phone"]');
phones.forEach(phone => {
  inpMask.mask(phone);
})
function validateForm(formEl) {
  const errorsUI = (selector, isValid) => {
		let field = selector;
		let display = isValid ? 'none' : 'block';
		let toggleMethod = isValid ? 'remove' : 'add';
		let errorBlock = field.parentElement.querySelector('.form__mistake');
		field.parentElement.classList[toggleMethod]('mistake');
		errorBlock ? errorBlock.style.display = display : false
  };

  const name = formEl.querySelector('[name="name"]');
  const phone = formEl.querySelector('[name="phone"]');
  const phoneIsValid = ()=> {
    return phone.value && (phone.value.replace(/[\s\-\_]/g, '').length == 12);
  }
  //$0.value.replace(/[\s\-\_]/g, '');

  errorsUI(name, name.value);
  errorsUI(phone, phoneIsValid());

  return name.value && phone.value;
  
}



//============================================================
//          menu
//============================================================

let hamburger = document.querySelector('.hamburger-menu'),
    body = document.body,
    menu = document.querySelector('.nav'),
    contactLink = document.querySelector('.nav__link_contact');

    hamburger.addEventListener('click', ()=> {
      hamburger.classList.toggle('active');
      menu.classList.toggle('active');
      body.classList.toggle('overflow')
    })

    contactLink.addEventListener('click', ()=> {
      menu.classList.remove('active');
      body.classList.remove('overflow');
      hamburger.classList.remove('active');
    })

//============================================================
//          hover-block
//============================================================

$(document).ready(function() {
  let width = document.documentElement.clientWidth;

  if(isElementExist('.industry__box')) {
    const industry = document.querySelectorAll('.industry__box');
    const industryBlockDefault = document.querySelector('.industry__description-defaut');
    const industryBlock = document.querySelectorAll('.industry__description-changing');
    const industryModal = document.querySelectorAll('.modal__industry');

    if ( width >= 1200 ) {
      industry.forEach(item => {
        item.addEventListener('mouseover', ()=> {
          const currentIndustry = item.getAttribute('data-industry');
          const currentIndustryBlock = document.querySelector(`.industry__description-changing[data-industry-block="${currentIndustry}"]`)
          
          industryBlockDefault.style.display = "none";

          industryBlock.forEach(item => {
            item.classList.remove('active');
          })
          
          currentIndustryBlock.classList.add('active');
        })
      })

      industry.forEach(item => {
        item.addEventListener('mouseout', ()=> {
          industryBlockDefault.style.display = "block";
          industryBlock.forEach(item => {
            item.classList.remove('active');
          })
        })
      })
    } else {
      const modal = document.querySelectorAll('.modal__industry');
      industry.forEach(item => {
        item.addEventListener('click', ()=> {
          const currentIndustry = item.getAttribute('data-industry');
          const currentIndustryModal = document.querySelector(`.modal__industry[data-industry-modal="${currentIndustry}"]`);
          currentIndustryModal.classList.add('active');
          body.classList.add('overflow');
        })
      })
    }
  }

  if(isElementExist('.modal')) {
    const btnClose = document.querySelectorAll('.modal__close');
    const btnBg = document.querySelectorAll('.modal__bg');
    const modal = document.querySelectorAll('.modal');

    function closeModal() {
        body.classList.remove('overflow');
        modal.forEach(item => item.classList.remove('active'));
    }
    btnClose.forEach(item => {
      item.addEventListener('click', closeModal)
    });

    btnBg.forEach(item => {
      item.addEventListener('click', closeModal)
    });
  }
})


    
// *****************************************************************
// Youtube Video player (checkout page)
// ***************************************************************
  
setTimeout(()=> {
  let tag = document.createElement('script');
	tag.src = "https://www.youtube.com/player_api";
	let firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
		
		let player = new YT.Player('player', {
			height: '360',
			width: '540',
			videoId: 'gfQCxDCwyW0',
			playerVars: {rel: 0, showinfo: 0, ecver: 2},
			// origin: 'http://localhost:9000',
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
		
		let buttonPlay = document.querySelectorAll('.button-play_btn'),
        // videoBackground = document.querySelector('.video'),
        modalVideo = document.querySelector('.modal-video'),
        modalBg = document.querySelector('.modal-video__bg'),
        modalCloseButton = document.querySelector('.modal-video__close'),
        modalBackground = document.querySelector('.video-wrapper');
		
		function closeModalVideo() {
			modalVideo.classList.remove('active');
			body.classList.remove('overflow');
		}
		
		function onPlayerReady(event) {
      // playVideo();
      buttonPlay.forEach(item => {
        item.addEventListener('click', ()=> {
          modalVideo.classList.add('active');
          body.classList.add('overflow');
				  player.playVideo();
        })
      })
			//   buttonPlay.addEventListener('click', () => {
      //   modalVideo.classList.add('active');
      //   body.classList.add('overflow');
			// 	player.playVideo();
				
			// });
		}
		
		var done = false;
		
		function onPlayerStateChange(event) {
			if (isElementExist('.modal-video__close')) {
				modalCloseButton.addEventListener('click', closeModalVideo);
			}
			if (isElementExist('.modal-video__bg')) {
				modalBg.addEventListener('click', closeModalVideo);
			}
		}
		
		if (isElementExist('.modal-video__close')) {
			modalCloseButton.addEventListener('click', stopVideo);
		}
		if (isElementExist('.modal-video__bg')) {
			modalBg.addEventListener('click', stopVideo);
		}
		
		function stopVideo() {
			player.stopVideo();
		}
  }
},0);

	
  


$(document).ready(function(){
  const firstBlockHeight = document.querySelector('.first-block').offsetHeight;
  const headerHeight = document.querySelector('.header').offsetHeight;
  const scrollbar = document.querySelector('.scrollbar');
  const scrollbarInner = document.querySelector('.scrollbar__inner');
  const totalHeight = firstBlockHeight + headerHeight;
  scrollbarInner.style.height = headerHeight * 100 / totalHeight + "%";
  
  
  
  console.log(firstBlockHeight - scrollbar.offsetHeight - scrollbar.offsetY);
  
  window.addEventListener('scroll', (e)=> {
    const innitialHeight = scrollbarInner.style.height;
    if (window.scrollY >= headerHeight && window.scrollY <= totalHeight) {
      scrollbarInner.style.height = window.scrollY * 100 / totalHeight + "%";
    }
    
  })
}) 
  